<template>
  <div id="form">
    <!-- Form -->
    <el-form
      :ref="formName"
      :model="queryAdd"
      :rules="queryRule"
      label-width="120px"
      label-position="left">
      <!-- 排序 -->
      <el-form-item
        label="排序"
        prop="sort">
        <el-input max="100" v-model="queryAdd.sort" />
      </el-form-item>
      <!-- 问题 -->
      <el-form-item
        label="问题"
        prop="question">
        <el-input v-model="queryAdd.question" />
      </el-form-item>
      <!-- 答覆 -->
      <el-form-item
        label="答覆"
        prop="content">
        <tinymce-editor v-model="queryAdd.content" type="faq" :id="queryAdd.random_dirname" />
      </el-form-item>
    </el-form>
    <!-- Footer -->
    <div
      slot="footer"
      class="dialog-footer">
      <!-- 取消 -->
      <el-button @click="handleCancel">
        取消
      </el-button>
      <!-- 确认 -->
      <el-button
        type="primary"
        @click="handleSubmit">
        确认
      </el-button>
    </div>
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { postAddFAQ } from '@/api/admin/help'
import TinymceEditor from '@/components/admin/tinymce-editor.vue'

export default {
  components: {
    TinymceEditor
  },
  data () {
    return {
      formName: 'addForm',
      queryAdd: {
        sort: '',
        question: '',
        answer: 'answer',
        content: '',
        is_visible: 1,
        random_dirname: ''
      },
      queryRule: {
        sort: [
          {
            required: true,
            message: '请输入排序數字',
            trigger: 'blur'
          }
        ],
        question: [
          {
            required: true,
            message: '请输入问题',
            tribber: 'blur'
          }
        ],
        content: [
          {
            required: true,
            message: '请输入答复内容',
            tribber: 'blur'
          }
        ]
      },
      editorInit: {
        height: 300
      }
    }
  },
  created () {
    this.queryAdd.random_dirname = 'rand' + Math.floor(Math.random() * 100000)
  },
  methods: {
    // UploadAdapter (loader) {
    //   const _this = this
    //   this.loader = loader
    //   loader.file.then(response => {
    //     _this.queryUpload.upload = response
    //     _this.queryUpload.id = 'rand' + Math.floor(Math.random() * 100000)
    //     const data = _this.queryUpload
    //     this.upload = () => {
    //       return ckUploadFiles(data).then(response => response.json()).then(downloadUrl => {
    //         return {
    //           default: downloadUrl
    //         }
    //       }).catch(error => {
    //         console.log(error)
    //       })
    //     }
    //   })
    //   this.abort = () => {
    //     console.log('Abort upload.')
    //   }
    // },
    handleSubmit () {
      this.$refs[this.formName].validate(valid => {
        if (valid)
          this.addFAQ()
      })
    },
    handleCancel () {
      // this.$refs[this.formName].resetFields()
      // this.dialogVisible = false
      this.$router.go(-1)
    },
    addFAQ () {
      postAddFAQ(this.queryAdd)
        .then(res => {
          switch (res.status) {
            case resStatus.OK:
              this.$router.go(-1)
              break
          }
          if (res.message.length !== 0) {
            this.$message(res.message)
          }
        })
        .catch(err => {
          this.$message.error(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
#form{
  max-width: calc((100vw - 200px) * 0.8);
}
.el-input-number {
  width: 100%;
}
</style>
